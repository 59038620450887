import React from "react"
import RacePageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "La Fleche Wallonne",
      edition: 2022,
      what: "Race data",
      updated: "2022-04-19 17:00:42",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21801: {
        teamId: "21801",
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00c3\u00abn Team",
        teamNationCode: "FRA",
      },
      21803: {
        teamId: "21803",
        teamUciCode: "AST",
        teamName: "Astana Qazaqstan Team",
        teamNationCode: "KAZ",
      },
      21804: {
        teamId: "21804",
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
      },
      21809: {
        teamId: "21809",
        teamUciCode: "QST",
        teamName: "Quick-Step Alpha Vinyl Team",
        teamNationCode: "BEL",
      },
      21812: {
        teamId: "21812",
        teamUciCode: "EFE",
        teamName: "EF Education-EasyPost",
        teamNationCode: "USA",
      },
      21814: {
        teamId: "21814",
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
      },
      21818: {
        teamId: "21818",
        teamUciCode: "ISN",
        teamName: "Israel-Premier Tech",
        teamNationCode: "ISR",
      },
      21820: {
        teamId: "21820",
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
      },
      21823: {
        teamId: "21823",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      21827: {
        teamId: "21827",
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
      },
      21830: {
        teamId: "21830",
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
      },
      21833: {
        teamId: "21833",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21837: {
        teamId: "21837",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      21839: {
        teamId: "21839",
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
      },
      21843: {
        teamId: "21843",
        teamUciCode: "UXT",
        teamName: "Uno-X Pro Cycling Team",
        teamNationCode: "NOR",
      },
      21845: {
        teamId: "21845",
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
      },
      21850: {
        teamId: "21850",
        teamUciCode: "BBK",
        teamName: "B&B Hotels-KTM",
        teamNationCode: "FRA",
      },
      21856: {
        teamId: "21856",
        teamUciCode: "IWG",
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        teamNationCode: "BEL",
      },
      21865: {
        teamId: "21865",
        teamUciCode: "ARK",
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        teamNationCode: "FRA",
      },
      21868: {
        teamId: "21868",
        teamUciCode: "TEN",
        teamName: "TotalEnergies",
        teamNationCode: "FRA",
      },
      22245: {
        teamId: "22245",
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
      },
      22290: {
        teamId: "22290",
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
      },
      22480: {
        teamId: "22480",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
      23137: {
        teamId: "23137",
        teamUciCode: "BWB",
        teamName: "Bingoal Pauwels Sauces WB",
        teamNationCode: "BEL",
      },
      23398: {
        teamId: "23398",
        teamUciCode: "SVB",
        teamName: "Sport Vlaanderen-Baloise",
        teamNationCode: "BEL",
      },
    },
    riders: {
      6402: {
        id: 6402,
        startno: 246,
        firstName: "Eliot",
        lastName: "Lietaer",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-08-15",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      872: {
        id: 872,
        startno: 22,
        firstName: "Gorka",
        lastName: "Izagirre",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1987-10-07",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      37717: {
        id: 37717,
        startno: 153,
        firstName: "Dorian",
        lastName: "Godon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-05-25",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      2741: {
        id: 2741,
        startno: 103,
        firstName: "Gianluca",
        lastName: "Brambilla",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-08-22",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      407: {
        id: 407,
        startno: 54,
        firstName: "Vegard Stake",
        lastName: "Laengen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1989-02-07",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      76716: {
        id: 76716,
        startno: 186,
        firstName: "Henri",
        lastName: "Vandenabeele",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-04-15",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      2325: {
        id: 2325,
        startno: 143,
        firstName: "Jesus",
        lastName: "Herrada",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-07-26",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      14716: {
        id: 14716,
        startno: 191,
        firstName: "Alexis",
        lastName: "Vuillermoz",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-06-01",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      133: {
        id: 133,
        startno: 121,
        firstName: "Vincenzo",
        lastName: "Nibali",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1984-11-14",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      37281: {
        id: 37281,
        startno: 91,
        firstName: "Aleksandr",
        lastName: "Vlasov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1996-04-23",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      19784: {
        id: 19784,
        startno: 173,
        firstName: "Jack",
        lastName: "Haig",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1993-09-06",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      12482: {
        id: 12482,
        startno: 111,
        firstName: "Xandro",
        lastName: "Meurisse",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-01-31",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      1937: {
        id: 1937,
        startno: 33,
        firstName: "Guillaume",
        lastName: "Boivin",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1989-05-25",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      12474: {
        id: 12474,
        startno: 1,
        firstName: "Julian",
        lastName: "Alaphilippe",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-06-11",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      169: {
        id: 169,
        startno: 244,
        firstName: "Jonathan",
        lastName: "Hivert",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1985-03-23",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      50288: {
        id: 50288,
        startno: 237,
        firstName: "Luc",
        lastName: "Wirtgen",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1998-07-07",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      6184: {
        id: 6184,
        startno: 76,
        firstName: "Sebastien",
        lastName: "Reichenbach",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1989-05-28",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      1783: {
        id: 1783,
        startno: 212,
        firstName: "Thomas",
        lastName: "De Gendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1986-11-06",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      264: {
        id: 264,
        startno: 34,
        firstName: "Jakob",
        lastName: "Fuglsang",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1985-03-22",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      352: {
        id: 352,
        startno: 74,
        firstName: "Matthieu",
        lastName: "Ladagnous",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1984-12-12",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      37376: {
        id: 37376,
        startno: 177,
        firstName: "Stephen",
        lastName: "Williams",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1996-06-09",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      729: {
        id: 729,
        startno: 17,
        firstName: "Jos",
        lastName: "Van Emden",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1985-02-18",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      45356: {
        id: 45356,
        startno: 227,
        firstName: "Aaron",
        lastName: "Van Poucke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-04-04",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      16610: {
        id: 16610,
        startno: 181,
        firstName: "S\u00c3\u00b8ren Kragh",
        lastName: "Andersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-08-10",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      16566: {
        id: 16566,
        startno: 145,
        firstName: "Anthony",
        lastName: "Perez",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-04-22",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      49957: {
        id: 49957,
        startno: 185,
        firstName: "Florian",
        lastName: "Stork",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1997-04-27",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      67838: {
        id: 67838,
        startno: 162,
        firstName: "Idar",
        lastName: "Andersen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1999-04-30",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      3165: {
        id: 3165,
        startno: 3,
        firstName: "Pieter",
        lastName: "Serry",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-11-21",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      25726: {
        id: 25726,
        startno: 56,
        firstName: "Marc",
        lastName: "Soler",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1993-11-22",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      51269: {
        id: 51269,
        startno: 194,
        firstName: "Valentin",
        lastName: "Ferron",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-02-08",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      20616: {
        id: 20616,
        startno: 125,
        firstName: "Antonio",
        lastName: "Nibali",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-09-23",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      3224: {
        id: 3224,
        startno: 93,
        firstName: "Cesare",
        lastName: "Benedetti",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1987-08-03",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      2211: {
        id: 2211,
        startno: 81,
        firstName: "Michael",
        lastName: "Matthews",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-09-26",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      84019: {
        id: 84019,
        startno: 2,
        firstName: "Remco",
        lastName: "Evenepoel",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-01-25",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      45354: {
        id: 45354,
        startno: 222,
        firstName: "Ruben",
        lastName: "Apers",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-08-25",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      7781: {
        id: 7781,
        startno: 27,
        firstName: "Carlos",
        lastName: "Verona",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1992-11-04",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      903: {
        id: 903,
        startno: 243,
        firstName: "Cyril",
        lastName: "Gautier",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1987-09-26",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      14214: {
        id: 14214,
        startno: 236,
        firstName: "Marco",
        lastName: "Tizza",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-02-06",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      2294: {
        id: 2294,
        startno: 35,
        firstName: "Reto",
        lastName: "Hollenstein",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1985-08-22",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      602: {
        id: 602,
        startno: 132,
        firstName: "Jan",
        lastName: "Bakelants",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1986-02-14",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      27124: {
        id: 27124,
        startno: 65,
        firstName: "Daniel",
        lastName: "Martinez",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1996-04-25",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      16635: {
        id: 16635,
        startno: 44,
        firstName: "Elie",
        lastName: "Gesbert",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-07-01",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      32592: {
        id: 32592,
        startno: 196,
        firstName: "Paul",
        lastName: "Ourselin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-04-13",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      27240: {
        id: 27240,
        startno: 85,
        firstName: "Jan",
        lastName: "Maas",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-02-19",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      37494: {
        id: 37494,
        startno: 207,
        firstName: "Neilson",
        lastName: "Powless",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1996-09-03",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      53490: {
        id: 53490,
        startno: 225,
        firstName: "Rune",
        lastName: "Herregodts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-07-27",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      7572: {
        id: 7572,
        startno: 106,
        firstName: "Alexander",
        lastName: "Kamp",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1993-12-14",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      353: {
        id: 353,
        startno: 77,
        firstName: "Anthony",
        lastName: "Roux",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1987-04-18",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      95698: {
        id: 95698,
        startno: 15,
        firstName: "Michel",
        lastName: "He\u00c3\u009fmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2001-04-06",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      38146: {
        id: 38146,
        startno: 184,
        firstName: "Joris",
        lastName: "Nieuwenhuis",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-02-11",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      39167: {
        id: 39167,
        startno: 234,
        firstName: "Kenny",
        lastName: "Molly",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-12-24",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      38: {
        id: 38,
        startno: 21,
        firstName: "Alejandro",
        lastName: "Valverde",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1980-04-25",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      349: {
        id: 349,
        startno: 152,
        firstName: "Mika\u00c3\u00abl",
        lastName: "Ch\u00c3\u00a9rel",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1986-03-17",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      66495: {
        id: 66495,
        startno: 92,
        firstName: "Giovanni",
        lastName: "Aleotti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-05-25",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      17252: {
        id: 17252,
        startno: 205,
        firstName: "Odd Christian",
        lastName: "Eiking",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-12-28",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      7964: {
        id: 7964,
        startno: 117,
        firstName: "Gianni",
        lastName: "Vermeersch",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-11-19",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      2977: {
        id: 2977,
        startno: 141,
        firstName: "Ion",
        lastName: "Izagirre",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-02-04",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      70767: {
        id: 70767,
        startno: 195,
        firstName: "Alan",
        lastName: "Jousseaume",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-08-03",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      1006: {
        id: 1006,
        startno: 172,
        firstName: "Damiano",
        lastName: "Caruso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-10-12",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      44341: {
        id: 44341,
        startno: 123,
        firstName: "Stefan",
        lastName: "de Bod",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1996-11-17",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      44537: {
        id: 44537,
        startno: 115,
        firstName: "Robert",
        lastName: "Stannard",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-09-16",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      16786: {
        id: 16786,
        startno: 12,
        firstName: "Tiesj",
        lastName: "Benoot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-03-11",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      16569: {
        id: 16569,
        startno: 31,
        firstName: "Michael",
        lastName: "Woods",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1986-10-12",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      6255: {
        id: 6255,
        startno: 83,
        firstName: "Tsgabu",
        lastName: "Grmay",
        nationCode: "ETH",
        nationName: "Ethiopia",
        birthDate: "1991-08-25",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      72919: {
        id: 72919,
        startno: 147,
        firstName: "Axel",
        lastName: "Zingle",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-12-18",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      6413: {
        id: 6413,
        startno: 154,
        firstName: "Bob",
        lastName: "Jungels",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1992-09-22",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      37259: {
        id: 37259,
        startno: 174,
        firstName: "Gino",
        lastName: "M\u00c3\u00a4der",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-01-04",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      8068: {
        id: 8068,
        startno: 176,
        firstName: "Dylan",
        lastName: "Teuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-03-01",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      38195: {
        id: 38195,
        startno: 11,
        firstName: "Jonas",
        lastName: "Vingegaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1996-12-10",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      22272: {
        id: 22272,
        startno: 72,
        firstName: "Bruno",
        lastName: "Armirail",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-04-11",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      3299: {
        id: 3299,
        startno: 116,
        firstName: "Scott",
        lastName: "Thwaites",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1990-02-12",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      929: {
        id: 929,
        startno: 171,
        firstName: "Wout",
        lastName: "Poels",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-10-01",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      66276: {
        id: 66276,
        startno: 224,
        firstName: "Gilles",
        lastName: "De Wilde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-10-12",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      27350: {
        id: 27350,
        startno: 167,
        firstName: "Jonas",
        lastName: "Gregaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1996-07-30",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      38937: {
        id: 38937,
        startno: 144,
        firstName: "Victor",
        lastName: "Lafay",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-01-17",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      65440: {
        id: 65440,
        startno: 183,
        firstName: "Leon",
        lastName: "Heinschke",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1999-11-08",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      87900: {
        id: 87900,
        startno: 161,
        firstName: "Tobias Halland",
        lastName: "Johannessen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1999-08-23",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      47176: {
        id: 47176,
        startno: 217,
        firstName: "Viktor",
        lastName: "Verschaeve",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-08-03",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      114659: {
        id: 114659,
        startno: 52,
        firstName: "Juan",
        lastName: "Ayuso",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2002-09-16",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      14712: {
        id: 14712,
        startno: 182,
        firstName: "Romain",
        lastName: "Combaud",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-04-01",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      20581: {
        id: 20581,
        startno: 122,
        firstName: "Leonardo",
        lastName: "Basso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-12-25",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      2961: {
        id: 2961,
        startno: 64,
        firstName: "Michal",
        lastName: "Kwiatkowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1990-06-02",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      27333: {
        id: 27333,
        startno: 62,
        firstName: "Laurens",
        lastName: "De Plus",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-09-04",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      16947: {
        id: 16947,
        startno: 197,
        firstName: "Cristian",
        lastName: "Rodriguez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-03-03",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      66841: {
        id: 66841,
        startno: 242,
        firstName: "Alan",
        lastName: "Boileau",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-06-25",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      20584: {
        id: 20584,
        startno: 203,
        firstName: "Alberto",
        lastName: "Bettiol",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-10-29",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      6538: {
        id: 6538,
        startno: 55,
        firstName: "Jan",
        lastName: "Polanc",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1992-05-06",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      6245: {
        id: 6245,
        startno: 95,
        firstName: "Patrick",
        lastName: "Konrad",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1991-10-13",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      6229: {
        id: 6229,
        startno: 113,
        firstName: "Jimmy",
        lastName: "Janssens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-05-30",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      6496: {
        id: 6496,
        startno: 42,
        firstName: "Winner",
        lastName: "Anacona",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1988-08-11",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      965: {
        id: 965,
        startno: 43,
        firstName: "Anthony",
        lastName: "Delaplace",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1989-09-11",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      68393: {
        id: 68393,
        startno: 133,
        firstName: "Th\u00c3\u00a9o",
        lastName: "Delacroix",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-02-21",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      79955: {
        id: 79955,
        startno: 16,
        firstName: "Gijs",
        lastName: "Leemreize",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-10-23",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      82912: {
        id: 82912,
        startno: 187,
        firstName: "Kevin",
        lastName: "Vermaerke",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2000-10-16",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      6228: {
        id: 6228,
        startno: 211,
        firstName: "Tim",
        lastName: "Wellens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-05-10",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      77103: {
        id: 77103,
        startno: 105,
        firstName: "Mattias",
        lastName: "Skjelmose Jensen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "2000-09-26",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      17203: {
        id: 17203,
        startno: 75,
        firstName: "Quentin",
        lastName: "Pacher",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-01-06",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      7998: {
        id: 7998,
        startno: 214,
        firstName: "Matthew",
        lastName: "Holmes",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-12-08",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      41: {
        id: 41,
        startno: 14,
        firstName: "Robert",
        lastName: "Gesink",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1986-05-31",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      64689: {
        id: 64689,
        startno: 232,
        firstName: "Johan",
        lastName: "Meens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-07-07",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      82254: {
        id: 82254,
        startno: 97,
        firstName: "Frederik",
        lastName: "Wandahl",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "2001-05-09",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      34011: {
        id: 34011,
        startno: 151,
        firstName: "Beno\u00c3\u00aet",
        lastName: "Cosnefroy",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-10-17",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      794: {
        id: 794,
        startno: 57,
        firstName: "Diego",
        lastName: "Ulissi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-07-15",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      37301: {
        id: 37301,
        startno: 166,
        firstName: "Torjus",
        lastName: "Sleen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1997-03-30",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      70034: {
        id: 70034,
        startno: 4,
        firstName: "Mauri",
        lastName: "Vansevenant",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-06-01",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      84926: {
        id: 84926,
        startno: 66,
        firstName: "Carlos",
        lastName: "Rodriguez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2001-02-02",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      33063: {
        id: 33063,
        startno: 193,
        firstName: "Fabien",
        lastName: "Doubey",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-10-21",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      452: {
        id: 452,
        startno: 131,
        firstName: "Domenico",
        lastName: "Pozzovivo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1982-11-30",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      706: {
        id: 706,
        startno: 37,
        firstName: "Daryl",
        lastName: "Impey",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1984-12-06",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      37446: {
        id: 37446,
        startno: 94,
        firstName: "Jai",
        lastName: "Hindley",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-05-05",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      8078: {
        id: 8078,
        startno: 7,
        firstName: "Louis",
        lastName: "Vervaeke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-10-06",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      71209: {
        id: 71209,
        startno: 235,
        firstName: "Tom",
        lastName: "Paquot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-09-22",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      67846: {
        id: 67846,
        startno: 23,
        firstName: "Oier",
        lastName: "Lazkano",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1999-11-07",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      165: {
        id: 165,
        startno: 247,
        firstName: "Pierre",
        lastName: "Rolland",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1986-10-10",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      75603: {
        id: 75603,
        startno: 124,
        firstName: "Yevgeniy",
        lastName: "Fedorov",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "2000-02-16",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      80271: {
        id: 80271,
        startno: 82,
        firstName: "Alexandre",
        lastName: "Balmer",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "2000-05-04",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      5515: {
        id: 5515,
        startno: 135,
        firstName: "Laurens",
        lastName: "Huys",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-09-24",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      109114: {
        id: 109114,
        startno: 86,
        firstName: "Jesus David",
        lastName: "Pe\u00c3\u00b1a",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "2000-05-08",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      39175: {
        id: 39175,
        startno: 47,
        firstName: "Alan",
        lastName: "Riou",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-04-02",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      716: {
        id: 716,
        startno: 67,
        firstName: "Geraint",
        lastName: "Thomas",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1986-05-25",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      581: {
        id: 581,
        startno: 101,
        firstName: "Bauke",
        lastName: "Mollema",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1986-11-26",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      70369: {
        id: 70369,
        startno: 223,
        firstName: "Kamiel",
        lastName: "Bonneu",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-08-01",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      75478: {
        id: 75478,
        startno: 165,
        firstName: "Jacob",
        lastName: "Hindsgaul Madsen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "2000-07-14",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      918: {
        id: 918,
        startno: 104,
        firstName: "Tony",
        lastName: "Gallopin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-05-24",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      168: {
        id: 168,
        startno: 142,
        firstName: "Simon",
        lastName: "Geschke",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1986-03-13",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      38301: {
        id: 38301,
        startno: 231,
        firstName: "Mathijs",
        lastName: "Paasschens",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-03-18",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      63035: {
        id: 63035,
        startno: 213,
        firstName: "S\u00c3\u00a9bastien",
        lastName: "Grignard",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-04-29",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      1527: {
        id: 1527,
        startno: 25,
        firstName: "Enric",
        lastName: "Mas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-01-07",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      27301: {
        id: 27301,
        startno: 156,
        firstName: "Aur\u00c3\u00a9lien",
        lastName: "Paret-Peintre",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-02-27",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      10862: {
        id: 10862,
        startno: 114,
        firstName: "Kristian",
        lastName: "Sbaragli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-05-08",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      7771: {
        id: 7771,
        startno: 46,
        firstName: "Lukasz",
        lastName: "Owsian",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1990-02-24",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      16787: {
        id: 16787,
        startno: 32,
        firstName: "Jenthe",
        lastName: "Biermans",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-10-30",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      26: {
        id: 26,
        startno: 175,
        firstName: "Luis Le\u00c3\u00b3n",
        lastName: "S\u00c3\u00a1nchez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1983-12-24",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      27297: {
        id: 27297,
        startno: 241,
        firstName: "Franck",
        lastName: "Bonnamour",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-06-20",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      20378: {
        id: 20378,
        startno: 136,
        firstName: "Simone",
        lastName: "Petilli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-04",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      45992: {
        id: 45992,
        startno: 51,
        firstName: "Tadej",
        lastName: "Pogacar",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1998-09-21",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      16578: {
        id: 16578,
        startno: 26,
        firstName: "Gregor",
        lastName: "Muhlberger",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1994-04-04",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      22679: {
        id: 22679,
        startno: 112,
        firstName: "Floris",
        lastName: "De Tier",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-01-20",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      3199: {
        id: 3199,
        startno: 36,
        firstName: "Hugo",
        lastName: "Houle",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-09-27",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      6104: {
        id: 6104,
        startno: 41,
        firstName: "Warren",
        lastName: "Barguil",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-10-28",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      79947: {
        id: 79947,
        startno: 155,
        firstName: "Paul",
        lastName: "Lapeira",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2000-05-25",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      37325: {
        id: 37325,
        startno: 73,
        firstName: "Kevin",
        lastName: "Geniets",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1997-01-09",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      14123: {
        id: 14123,
        startno: 157,
        firstName: "Lawrence",
        lastName: "Warbasse",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1990-06-28",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      51348: {
        id: 51348,
        startno: 216,
        firstName: "Sylvain",
        lastName: "Moniquet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-01-14",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      1715: {
        id: 1715,
        startno: 84,
        firstName: "Christopher",
        lastName: "Juul-Jensen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1989-07-06",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      6222: {
        id: 6222,
        startno: 71,
        firstName: "Rudy",
        lastName: "Molard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1989-09-17",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      51252: {
        id: 51252,
        startno: 204,
        firstName: "Simon",
        lastName: "Carr",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-08-29",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      37308: {
        id: 37308,
        startno: 13,
        firstName: "Pascal",
        lastName: "Eenkhoorn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-02-08",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      27334: {
        id: 27334,
        startno: 233,
        firstName: "Remy",
        lastName: "Mertz",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-07-17",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      53723: {
        id: 53723,
        startno: 215,
        firstName: "Andreas",
        lastName: "Kron",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-06-01",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      27303: {
        id: 27303,
        startno: 146,
        firstName: "R\u00c3\u00a9my",
        lastName: "Rochas",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-05-18",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      76245: {
        id: 76245,
        startno: 206,
        firstName: "Ben",
        lastName: "Healy",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "2000-09-11",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      66347: {
        id: 66347,
        startno: 163,
        firstName: "Fredrik",
        lastName: "Dversnes",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1997-03-20",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      64650: {
        id: 64650,
        startno: 5,
        firstName: "Stan",
        lastName: "Van Tricht",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-09-20",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      7640: {
        id: 7640,
        startno: 63,
        firstName: "Omar",
        lastName: "Fraile",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-07-17",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      17183: {
        id: 17183,
        startno: 192,
        firstName: "J\u00c3\u00a9r\u00c3\u00a9my",
        lastName: "Cabot",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-07-24",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      27277: {
        id: 27277,
        startno: 126,
        firstName: "Simone",
        lastName: "Velasco",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-12-02",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      16992: {
        id: 16992,
        startno: 202,
        firstName: "Ruben",
        lastName: "Guerreiro",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1994-07-06",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      16576: {
        id: 16576,
        startno: 87,
        firstName: "Nick",
        lastName: "Schultz",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-09-13",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      76474: {
        id: 76474,
        startno: 6,
        firstName: "Ilan",
        lastName: "Van Wilder",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-05-14",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      45371: {
        id: 45371,
        startno: 226,
        firstName: "Jens",
        lastName: "Reynders",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-05-25",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      6409: {
        id: 6409,
        startno: 24,
        firstName: "Lluis",
        lastName: "Mas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-10-15",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      49901: {
        id: 49901,
        startno: 245,
        firstName: "Victor",
        lastName: "Koretzky",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-08-26",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      737: {
        id: 737,
        startno: 127,
        firstName: "Andrey",
        lastName: "Zeits",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1986-12-14",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      46163: {
        id: 46163,
        startno: 53,
        firstName: "Marc",
        lastName: "Hirschi",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-08-24",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      31066: {
        id: 31066,
        startno: 134,
        firstName: "Quinten",
        lastName: "Hermans",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-07-29",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      117: {
        id: 117,
        startno: 201,
        firstName: "Rigoberto",
        lastName: "Uran",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1987-01-26",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      38923: {
        id: 38923,
        startno: 45,
        firstName: "Simon",
        lastName: "Guglielmi",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-07-01",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      65025: {
        id: 65025,
        startno: 61,
        firstName: "Tom",
        lastName: "Pidcock",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-07-30",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      45602: {
        id: 45602,
        startno: 96,
        firstName: "Ide",
        lastName: "Schelling",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-02-06",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      94607: {
        id: 94607,
        startno: 221,
        firstName: "Jenno",
        lastName: "Berckmoes",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2001-02-04",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      48119: {
        id: 48119,
        startno: 164,
        firstName: "Morten",
        lastName: "Hulgaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-08-23",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      17182: {
        id: 17182,
        startno: 102,
        firstName: "Julien",
        lastName: "Bernard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-03-17",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      38803: {
        id: 38803,
        startno: 137,
        firstName: "Georg",
        lastName: "Zimmermann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1997-10-11",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      22143: {
        id: 22143,
        startno: 107,
        firstName: "Antwan",
        lastName: "Tolhoek",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-04-29",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Fleche Wallone"
  const raceID = 10

  return (
    <RacePageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
